import React from 'react'
import CheckboxList from './CheckboxList'
import { useAppData } from '../../hooks/useAppData.jsx'
import { Contract } from '../dados/Contract'

function CheckTermosContratacao() {

  const { setModalIsOpen } = useAppData()

  const defaultItemList = [
    {
      id: 2,
      name:
        'Termo de Contratação',
      disabled: true,
    },
  ]

  return (
    <>
      <div className="CheckboxList">
        <CheckboxList defaultItemList={defaultItemList} />
        <button className="botoes" onClick={() => setModalIsOpen(true)}>
          Ler e Concordar
        </button>
      </div>
      <Contract />
    </>
  )
}
export default CheckTermosContratacao

