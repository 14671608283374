import React  from "react";

function TituloPagina(props) {
    return(
        <div className="tituloPagina">
            <h1>{props.tituloh1}</h1>
            <h2>{props.tituloh2}</h2>
            <h3>{props.tituloh3}</h3>
        </div>
    )    
}
export default  TituloPagina;