import React from 'react';

export const PaginaTokenInvalido = () => {
  return (

          <div className="conteudoErro">
            <div className="caixaBrancaErro">              
              <div className="caixaMsgErro">
              <h2>Termo de Aceite</h2><br></br>
                <p className="textoErro">                 
                  <span className="bold">Erro ao carregar o contrato</span>
                  <br></br><br></br>
    
                  Por favor,tente mais tarde ou  entre em contato conosco :
                  <br></br>
                  <a target="_blank" rel="noreferrer"  
                  href="https://api.whatsapp.com/send?phone=5508004941234&text=Ol%C3%A1.%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%3A">
                    </a> 0800 494 1234
                    <br></br>
                    
                </p>
              </div>
            </div>
          </div>    
  );
};

export default PaginaTokenInvalido;
