import  React from "react";
import LogoHeader from "../templatePages/LogoHeader";
import TituloPagina from "../templatePages/TituloPagina";

function Cabecalho(props) {
    return(
        <header>
            <div className="cabecalho"> 
                <div className="Logo">
                    <LogoHeader />
                </div>
                <hr></hr>
                <div className="tituloPagina">
                    {/* <TituloPagina tituloh1="Aceite Eletrônico"/> */}
                    <TituloPagina tituloh1={props.titulo}/>
                </div>
            </div>
        </header>
    )
}
export default Cabecalho;