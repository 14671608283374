import  React from "react";
import logo from "../../img/logoJustweb.png";

class LogoHeader extends React.Component {
    render() {
        return(
            <div className="logoHeader">
                <div className="logoBox">
                    <img src={logo} alt="logo" className="logoHeader__logo" />
                </div>
            </div> 
        )
    }

}
export default LogoHeader;