import axios from 'axios';
import { handleSuccess, handleError } from './msgRetorno';
import Cookies from 'js-cookie';

//url e porta do servidor de API

const URL_SERVIDOR = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_URL_BACKEND : process.env.REACT_APP_DEV_URL_BACKEND;
const PORTA = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_PORT_BACKEND : process.env.REACT_APP_DEV_PORT_BACKEND;

const username = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_USERNAME_AUTH_API : process.env.REACT_APP_DEV_USERNAME_AUTH_API;
const password = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_PASSWORD_AUTH_API : process.env.REACT_APP_DEV_PASSWORD_AUTH_API;

//Codificiação de usuario e senha na base64 API
const credentials = username + ':' + password;
const base64Credentials = btoa(credentials);

//dados e chaves cookie
const API_URL_COOKIE = `${URL_SERVIDOR}:${PORTA}/assinatura/v1/politica_cookies/`
const COOKIE_KEYS = {
    TOKEN: 'token',
    ACEITOU_COOKIE: 'aceitouCookie',
    TIPO_DISPOSITIVO: 'tipoDispositivo',
    IP_ENDERECO: 'ipEndereco',
    LARGURA_DISPOSITIVO: 'larguraDispositivo',
    ALTURA_DISPOSITIVO: 'alturaDispositivo',
    SISTEMA_DISPOSITIVO: 'sistemaDispositivo',
};

//obter dados da assinatura de contrato
export async function getDadosAssinatura(codToken) {
    try {

        const myHeaders = {
            Authorization: 'Basic ' + base64Credentials,
        };

        const headers = {
            ...myHeaders,
            'Content-Type': 'multipart/form-data',
        };

    const resp = await axios
        .get(`${URL_SERVIDOR}:${PORTA}/assinatura/v1/assinatura_contrato/busca_token/${codToken}/`, { headers });
    const data = resp.data;

    return data[0];
    } catch (error) {
        if (error.resp) {
            console.error('Erro ao obter dados da assinatura:', error);
            return error.resp;
        }
    }
}

//obter dados do cliente 
export async function getDadosClientes(codToken) {

    try {      
        
        const myHeaders = {
            Authorization: 'Basic ' + base64Credentials,
        };

        const headers = {
            ...myHeaders,
            'Content-Type': 'multipart/form-data',
        };

        const response = await axios.get(`${URL_SERVIDOR}:${PORTA}/assinatura/v1/token/busca_token/${codToken}/`, { headers });
        return response.data[0];

    } catch (error) {

        return false;
    }
}

//ações ao enviar a confirmação de assinatura
async function send(selectedFiles, dadosClientes, setSubmitSuccess) {

    try {
    const myHeaders = {
        Authorization: 'Basic ' + base64Credentials,
    };

    const formDataList = [];
    for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append('link_arquivo', selectedFiles[i]);
        formData.append('cd_contrato', dadosClientes.cd_contrato);
        formData.append('st_assinatura', 'Assinado');
        formData.append('nm_arquivo', selectedFiles[i].name);
        formData.append('dt_assinatura', new Date(Date.now()).toISOString());
        formData.append('cd_token', dadosClientes.cd_token);

        formDataList.push(formData);
    }

    const headers = {
        ...myHeaders,
        'Content-Type': 'multipart/form-data',
    };

    await Promise.all(
        formDataList.map((formData) =>
            axios.post(`${URL_SERVIDOR}:${PORTA}/assinatura/v1/assinatura_contrato/`, formData, { headers })
        )
    );

        // Chamar a função de sucesso após o envio bem-sucedido
        handleSuccess(setSubmitSuccess);
    } catch (error) {
        // Chamar a função de erro em caso de falha
        handleError(error, setSubmitSuccess);
    }
}

// funcao que salva os cookies no banco de dados
export async function salvarCookies(aceitouCookie){    

    try {
        const token = Cookies.get(COOKIE_KEYS.TOKEN);
        let data = {};

        //quando o usuario aceita o cookie obter os dados e salvar no banco
        if (aceitouCookie === true){
            data = {
                token,
                fl_aceita_cookies:aceitouCookie,
                tp_dispositivo: Cookies.get(COOKIE_KEYS.TIPO_DISPOSITIVO) || '',
                dt_registro: new Date().toISOString(),
                ip_usuario: Cookies.get(COOKIE_KEYS.IP_ENDERECO) || '',
                largura_dispositivo: Cookies.get(COOKIE_KEYS.LARGURA_DISPOSITIVO) || '',
                altura_dispositivo: Cookies.get(COOKIE_KEYS.ALTURA_DISPOSITIVO)||'',
                sistema_dispositivo:Cookies.get(COOKIE_KEYS.SISTEMA_DISPOSITIVO) || '',
            };
            
        // quando o usuario nao aceita o cookie eu vou gravar apenas a data    
        }else if(aceitouCookie === false){
            data = {                
                fl_aceita_cookies:aceitouCookie,
                dt_registro: new Date().toISOString(),
                token:null,
            };
            //console.log('salvar quando recusar ',data);
        }

        //montar o cabecalho para a api
        const headers = {
            Authorization: 'Basic ' + base64Credentials,
            'Content-Type': 'application/json',
        };   

        //chama a api que grava os cookies no banco
        await axios.post(API_URL_COOKIE,data,{headers});
    
    } catch (error) {
        console.error('Erro: ',error);
    }
}

export default send ;
