
  // Função para carregar os cookies aceitos
  export const carregarCookies = async (token,aceitou) => {
    let cookies = {'aceitouCookie': aceitou,};
      try {

        if(aceitou){
          const ipAddress = await getIP(); 
          const largura = obterInformacoesDispositivo().largura;

          cookies = {
              'aceitouCookie': aceitou,
              'token': token,
              'ipEndereco': ipAddress,
              'alturaDispositivo': obterInformacoesDispositivo().altura,
              'larguraDispositivo': largura,
              'tipoDispositivo': obterTipoDeDispositivo(largura),
              'sistemaDispositivo': obterSistemaDispositivo(),
          };
        }   

      return cookies;

      } catch (error) {
          throw error;
      }
  };

  //define os cookies na sessao a partir de uma lista
  export const definirCookies = (cookies) => {
    Object.entries(cookies).forEach(([nome, valor]) => {
      document.cookie = `${nome}=${valor}`;
    });
  };

  //define os cookies na sessao a partir de uma lista
  export const limparCookies = (cookies) => {
    Object.entries(cookies).forEach(([nomeDoCookie]) => {
      document.cookie = `${nomeDoCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };
  
  // Função para obter o tipo de dispositivo
  export const obterTipoDeDispositivo = (largura) => {

    let tipoDispositivo ='';

    if(largura > 1920){
        tipoDispositivo = 'TV';
    }else if( largura > 1024 ){
        tipoDispositivo = 'Computador';
      }else if(largura > 600){
        tipoDispositivo = 'Tablet';
      }else if(largura <= 600 ){        
        tipoDispositivo = 'Celular';        
      }else{
        tipoDispositivo = 'não definido';
      }

      return tipoDispositivo;
  }

  //funcao para obter sistema Dispositivo
  export const obterSistemaDispositivo = () =>{

    let sistemaDispositivo ='';
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);

    if(isAndroid){
      sistemaDispositivo = 'Android';
    }else if(isIOS){
      sistemaDispositivo = 'IOS';
    }else{
      sistemaDispositivo = 'Outro sistema';
    }

    return sistemaDispositivo;
  }

  //obter informacoes do dispositivo(altura,largura,tipo etc)
  export const obterInformacoesDispositivo = () => {
    const altura = window.innerHeight;
    const largura = window.innerWidth;
    const tipoDispositivo = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
    const sistemaOperacional = /iPhone|iPad|iPod/i.test(navigator.userAgent) ? 'iOS' : 'Android';
  
    return {
      altura,
      largura,
      tipoDispositivo,
      sistemaOperacional,
    };
  }

  function  getIP() {
      return fetch('https://api.ipify.org?format=json')
          .then(response => {
              if (response.ok) {
                  return response.json();
              }
              throw new Error('Erro ao obter o endereço IP');
          })
          .then(data => data.ip)
          .catch(error => {
              console.log('Ocorreu um erro:', error);
              return null;
          });
  }

