import React, { useState, useEffect } from 'react';
import Rodape from '../templatePages/Rodape';
import { CheckboxFieldsProvider } from '../../contexts/checkboxFieldsContext';
import { AppDataProvider } from '../../contexts/appDataContext';
import { useHistory } from 'react-router-dom';
import LogoHeader from '../templatePages/LogoHeader';

export const PaginaObrigado = () => {
  const history = useHistory();
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 4000); 
    window.history.replaceState(null, null, window.location.href);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="paginaObrigado">
      <CheckboxFieldsProvider>
        <AppDataProvider appHistory={history}>
          <div className="conteudoObrigado">
            <div className="caixaBranca">
              <div className="logoDentroDaCaixa">
                <LogoHeader />
              </div>
              <div className="linhaLogo"></div>
              <div className="caixaMsgObrigado">
                {showMessage && (
                  <>
                    <h2 className="msgSucesso">Contrato assinado com sucesso!!</h2>
                    <br />
                  </>
                )}
                  <p className="textoObrigado">
                    Seja bem-vindo(a) à Justweb!
                    <br />
                    Agradecemos sinceramente por escolher a nossa empresa e confiar em nossos serviços.
                    Em breve entraremos em contato para agendar a data de instalação e fornecer mais orientações sobre o processo.
                    <br />
                    Atenciosamente, Equipe Justweb.
                  </p>
                  <br />
                  <p>
                  *Conduziremos para uma análise de viabilidade. 
                  Se,por algum motivo, a análise não for aprovada, 
                  seus dados pessoais serão tratados com confidencialidade e descartados.
                  </p>
              </div>
              <div className="rodape">
                <Rodape />
              </div>
            </div>
          </div>
        </AppDataProvider>
      </CheckboxFieldsProvider>
    </div>
  );
};

export default PaginaObrigado;