import React from 'react';
import CookiePolicy from './CookiePolicy';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <CookiePolicy/> 
        </div>
    );
};
export default PrivacyPolicy;
