import React from 'react'
import { toast } from 'react-toastify'
import { useAppData } from '../../hooks/useAppData.jsx'

export function FileUploadComponent() {
  const { selectedFiles, setSelectedFiles } = useAppData()
  const maxFiles = 4;
  // selecionar arquivos
  const handleFileChange = (event) => {

    const files = Array.from(event.target.files);

    // Filtrar apenas imagens e PDFs
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    const filteredFiles = files.filter((file) =>
      allowedTypes.includes(file.type) 
    );
  
    // Verificar limite máximo de arquivos
    
    const remainingSlots = maxFiles - selectedFiles.length;
    const toBeUploaded = filteredFiles.slice(0, remainingSlots);
  
    if (remainingSlots === 0) {
      const ignoredFiles = filteredFiles.slice(remainingSlots);
      const ignoredFileNames = ignoredFiles.map((file) => file.name).join(', ');
      toast(
        `Só é possível anexar no máximo ${maxFiles} arquivos. Os seguintes arquivos foram ignorados: ${ignoredFileNames}`,
        { type: 'error' }
      );
      return;
    }  

    // Verificar tamanho máximo de arquivo
    const maxSize = 15 * 1024 * 1024 // 15MB
    const invalid = toBeUploaded.filter((file) => file.size > maxSize)
    const ignoredFiles = files.filter((file) => !allowedTypes.includes(file.type));

    //retorna msg caso o usuario tente adicionar um arquivo com tamanho maior que o permitido
    if (invalid.length > 0) {
      const invalidFileNames = invalid.map((file) => file.name).join(', ');
      return toast(`Os seguintes arquivos excedem o tamanho máximo : ${invalidFileNames}`, { type: 'error' });
    }
    //retorna msg caso o tipo de arquivo nao seja permitido
    if (ignoredFiles.length > 0) {
      const ignoredFileNames = ignoredFiles.map((file) => file.name).join(', ');
      toast(`Os seguintes arquivos foram ignorados devido ao formato inválido: ${ignoredFileNames}`, { type: 'error' });
    }

    const validFiles = toBeUploaded.filter((file) => file.size <= maxSize)

    setSelectedFiles([...selectedFiles, ...validFiles])
  }

  //remover arquivos que ja foram selecionados anteriormente
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file)
    setSelectedFiles(updatedFiles)
    
  }
  
  return (
    <div className="DadosCliente">
      <div className="file-upload-component">
        <label htmlFor="file-input" className="file-label">
        {' '}
        Selecione os arquivos: <br /> OBS: Serão aceitos até {maxFiles} arquivos (Imagem ou PDF e que cada arquivo tenha no máximo 15mb)
        <br />
        <br />
        <strong>IMPORTANTE: Anexe uma foto do seu documento de identidade e uma foto do seu rosto segurando o documento.</strong>
      </label>
        <input
          id="file-input"
          type="file"
          accept=".jpg, .jpeg, .png, .pdf"
          multiple
          onChange={handleFileChange}
          className="file-input"
        />
        <div className="file-list">
          {selectedFiles.map((file, index) => (
            <div className="file-item" key={index}>
              <span className="file-name">{file.name}</span>
              <button
                onClick={() => handleRemoveFile(file)}
                className="btn file-remove-button"
              >
                Remover
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}