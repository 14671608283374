function List(props) {
    return (
        <div className="client-details">
        <div>
            <p><b>Nome: </b>{props.Nome}</p>
            <p><b>Plano contratado: </b>{props.Plano}</p>
        </div>

        <div className="address-details">
            <br></br>
            <p><b>Endereço de instalação:</b></p>
            <p>{props.end_rua} ,Nº: {props.end_numero}, 
            {props.end_bairro}, {props.end_cidade} - {props.end_estado}
            ,Cep: {props.end_cep} </p>  <p> complemento: {props.end_complemento}</p>
        </div>
    <br></br>
        {/* <div className="confirmation-checkbox">
            <input type="checkbox" id="confirmAddress" name="confirmAddress" />
            <label htmlFor="confirmAddress" style={{ fontWeight: "bold", fontSize: "14px", marginLeft: '5px' }}> 
                Estou ciente de que o endereço de instalação é o indicado acima e confirmo que todos os dados estão corretos.
            </label>
        </div> */}
        </div>
    );
    }

    export default List;
