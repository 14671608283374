import React, { useState} from 'react';
import Modal from 'react-modal';
import PrivacyPolicy from './PrivacyPolicy';
import { useParams } from 'react-router-dom';
import { definirCookies,carregarCookies, limparCookies} from './definirCookies';
import { salvarCookies } from '../../banco_dados/funcoesApi';

Modal.setAppElement('#root');

const CookieConsentModal = () => {
  const [mostrarModalCookie, setMostrarModalCookie] = useState(true);
  const [mostrarModalPolitica, setMostrarModalPolitica] = useState(false);
  const { token } = useParams(); 

  //quando o usuario aceitar o cookie
  const quandoAceitar = async() => {

    //fechar janela modal de cookie
    setMostrarModalCookie(false);

    //obter e gravar dados do cookie
    const aceitouCookie = true;    

    //limpar cookies anteriores
    const cookiesLimpar = await carregarCookies(token,aceitouCookie);
    limparCookies(cookiesLimpar);
    
    //definir cookies atuais
    const novosCookies = await carregarCookies(token,aceitouCookie);
    definirCookies(novosCookies);

    //salvar cookies no banco de dados
    await salvarCookies(aceitouCookie);
  }

  // quando o usuario recusar os cookies
  const quandoRecusar = async () => {

    //fechar janela modal de cookies
    setMostrarModalCookie(false);

    //obter e salvar cookies de recusa
    const aceitouCookie = false;
    
    //limpar cookies anteriores
    const cookiesLimpar = await carregarCookies(token,aceitouCookie);
    limparCookies(cookiesLimpar);

    // definir novos data de recusa nos cookies
    const novosCookies = await carregarCookies(token,aceitouCookie);
    definirCookies(novosCookies);

    //salvar data de recusa no banco de dados
    await salvarCookies(aceitouCookie);
  }

  //abrir janela modal politica de cookies
  const abrirModalPolitica = () => {
    setMostrarModalPolitica(true);
  }

  //fechar a janela modal da politica de cookies
  const fecharModalPolitica = () => {
    setMostrarModalPolitica(false);
  }

  //fechar a janela modal se aceita ou nao os cookies
  const fecharModalCookie = () => {
    setMostrarModalCookie(false);
  }

  if (!mostrarModalCookie) {
    return null;
  }

// Estilos personalizados para o modal de política de cookies
const modalPoliticaStyles = {
  content: {
    maxWidth: '55%', 
    margin: '0 auto',
    padding: '2%',
  }
};

// estilos para telas menores usando uma media query
if (window.innerWidth < 768) {
  modalPoliticaStyles.content.maxWidth = '95%'; 
  modalPoliticaStyles.content.padding = '10%'; 
  modalPoliticaStyles.content.margin = 'auto';
}

  return (
    <>
      <Modal 
        isOpen={mostrarModalCookie} 
        onRequestClose={fecharModalCookie}
        className="modal-content"
        shouldCloseOnOverlayClick={false}
        overlayClassName="cookieOverlay"
      >
        <h2>Política de Cookies</h2>
        <p>
          Ao clicar em "Aceitar Cookies", você concorda com o uso destes para aprimorar
          sua experiência, personalizar o conteúdo, disponibilizar funcionalidades
          de mídia social e analisar o tráfego. Saiba mais em nossa{' '}
        <button className='btn-policy'
          onClick={() => {abrirModalPolitica();}}
        >
          Política de Cookies
        </button>

        </p>
        <br />
        <br />
        <div className="button-container">
          <button className="btn btn-primary" onClick={quandoAceitar}>
            Aceitar Cookies
          </button>
          <button className="btn btn-danger" onClick={quandoRecusar}>
            Recusar Cookies
          </button>
        </div>
      </Modal>

      {/* Modal politica de privacidade */}
      <Modal
        isOpen={mostrarModalPolitica}
        onRequestClose={fecharModalPolitica}
        shouldCloseOnOverlayClick={false}
        overlayClassName="cookieOverlay"
        style={modalPoliticaStyles}
      >       
        <button className="btn btn-danger botaoFecharPrivacidade" onClick={fecharModalPolitica}>
          Fechar
        </button>
        <PrivacyPolicy/>
      </Modal>    
    </>
  );  
};
export default CookieConsentModal;