import { createContext, useState } from 'react'

export const CheckboxFieldsContext = createContext({})

export function CheckboxFieldsProvider({ children }) {
  const [fildsChecks, setFieldsChecks] = useState([])

  function addFields(id) {
    return setFieldsChecks((state) => [...state, id])
  }

  function removeFields(id) {
    const fields = fildsChecks.filter((itemId) => itemId !== id)
    return setFieldsChecks(fields)
  }

  function isCheckAll() {
    const arrayChecks = [1, 2, 3, 4, 5,6]
    const allCheck =
      arrayChecks.length === fildsChecks.length &&
      arrayChecks.every((elemento) => fildsChecks.includes(elemento))
    return allCheck
  }

  function isCheck(id) {
    const isCheck = fildsChecks.find((itemId) => itemId === id)

    if (isCheck) {
      return true
    }

    return false
  }

  return (
    <CheckboxFieldsContext.Provider
      value={{ addFields, removeFields, isCheckAll, isCheck }}
    >
      {children}
    </CheckboxFieldsContext.Provider>
  )
}