import React from 'react';
import Cabecalho from '../templatePages/Cabecalho';
import Conteudo from '../templatePages/Conteudo';
import Rodape from '../templatePages/Rodape';
import { getDadosAssinatura, getDadosClientes} from '../../banco_dados/funcoesApi';
import history from '../../hooks/history';
import { useParams } from 'react-router-dom';
import TituloPagina from '../templatePages/TituloPagina';
import { toast } from 'react-toastify'
import { useAppData } from '../../hooks/useAppData.jsx';


export const PaginaAssinatura = () => {
  const { token } = useParams();
  const { cpfDigits, setCpfDigits } = useAppData();
  const { isCpfValid,setIsCpfValid } = useAppData(); 
  const { setDadosClientes} = useAppData();

  const Icon = () => (
    <i className="fa fa-lock" aria-hidden="true"></i>
  );

  const handleVerificarCPF = async () => {

    try {

      const dadosCliente = await getDadosClientes(token);

      if(dadosCliente.cd_token){
      
        const assinaturaData = await getDadosAssinatura(dadosCliente.cd_token);
        const cdCpfCnpj = dadosCliente.nu_cpf_cnpj.toString().substring(0, 5);
        const numCpfCpnj = cpfDigits.replace(/\D/g, '');

        if (!/^\d{5}$/.test(numCpfCpnj)) {
          // Caso não tenha sido digitado exatamente 5 dígitos do CPF ou CNPJ
          throw new Error("Você deve digitar exatamente 5 dígitos do seu CPF ou CNPJ.");
        }

        if (numCpfCpnj.length !== 5) {
          // Caso não tenham sido digitados 5 dígitos
          throw new Error("Você deve digitar exatamente 5 primeiros dígitos do seu CPF ou CNPJ.");
        }

        if (!cdCpfCnpj) {
          // Caso não tenha encontrado o CPF
          throw new Error("Cpf ou Cnpj não encontrados. Certifique-se de ter digitado os 5 primeiros dígitos corretamente.");
        }      

        if (cpfDigits !== cdCpfCnpj) {
          // Caso os 5 primeiros dígitos do CPF/CNPJ não correspondam aos do contrato
          throw new Error("Os 5 primeiros dígitos do CPF/CNPJ não correspondem aos do contrato.");
        }

        if (dadosCliente.st_token !== 'Ativo') {
          // Redirecionamento quando o token estiver expirado/desativado
          history.replace('/tokenInvalido');
          window.location.reload();
        }

        if (assinaturaData && assinaturaData.st_assinatura === 'Assinado' && dadosCliente.st_token === 'Ativo') {
          // Redirecionamento quando assinado
          history.replace('/tokenAssinado');
          window.location.reload();
        }

        // Validado tudo ok para abrir o contrato
        setCpfDigits(numCpfCpnj);
        setIsCpfValid(true); // Marque o CPF como válido   
        setDadosClientes(dadosCliente);
    }else {
      console.error('Erro ao carregar os dados do cliente. Possivel problema erro ao acessar o servidor.')
      const erro = 'Desculpe, não foi possível carregar os dados do contrato com este link.';
      toast(erro, {type : 'error'});
    }
    
    }catch(error){

      console.error("Erro ao verificar o CPF:",error);
      setIsCpfValid(false);
      setCpfDigits('');
      setDadosClientes(null);
      toast(error.message, {type: 'error'});
    }
  };

  return (

    <div>
      {isCpfValid ? (
        <div>
              <div>
                <Cabecalho titulo="Aceite Eletrônico"/>
              </div>
              <div>
                <Conteudo />
              </div>
              <div>
                <Rodape />
              </div>

        </div>
      ) : (
        <div>
              <div>        
                <Cabecalho titulo={<><Icon />Acesso Seguro</>}/>
              </div>
                <div>
                <div className="input-container">
                  <label htmlFor="cpfInput">
                    <TituloPagina tituloh3="Proteção: Informe 5 primeiros dígitos do CPF/CNPJ." />
                  </label>
                    <div className="input-button-container">
                      <input
                        type="password"
                        id="cpfInput"
                        placeholder="Digite aqui para visualizar o contrato."
                        value={cpfDigits}
                        onChange={(e) => setCpfDigits(e.target.value)}
                        autoComplete="off"
                      />
                      <button className="btn btn-danger" onClick={handleVerificarCPF}>
                        Verificar CPF/CNPJ
                      </button>
                    </div>
                    <br></br>
                    <p>Essa medida é uma camada adicional de segurança para proteger seus dados.Não se preocupe,<br></br> seus dados estão protegidos conosco segundo as normas da LGPD.</p>
                </div>
                </div>
              <div>
                <Rodape />
              </div>
        </div>
      )}
    </div>
  );
};

export default PaginaAssinatura;