import React from "react";

function Rodape() {
    let year = new Date().getFullYear();
    return( 
        <footer className="rodape">            
            <ul className="rodape__lista">
                <li className="lista__link">
                    {/* <img src={logo} alt="Justweb-logo" class="rodape__logo"/> */}
                    <a target="_blank" rel="noreferrer" className="lista__texto" href="https://api.whatsapp.com/send?phone=5508004941234&text=Ol%C3%A1.%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%3A">
                        <u>Telefone/WhatsApp: 0800 494 1234</u></a>
                </li>                
            </ul>
            <p className="rodape__texto">&copy; {year} Justweb Telecomunicações. Todos os direitos reservados.</p>
        </footer>        
    )
}
export default Rodape;