import React from 'react';
import Rodape from '../templatePages/Rodape';
import { CheckboxFieldsProvider } from '../../contexts/checkboxFieldsContext';
import { AppDataProvider } from '../../contexts/appDataContext';
import { useHistory } from 'react-router-dom';
import LogoHeader from '../templatePages/LogoHeader';

export const PaginaAssinado = () => {
  const history = useHistory();

  return (
    <div className="paginaAssinado">
      <CheckboxFieldsProvider>
        <AppDataProvider appHistory={history}>
          <div className="conteudoAssinado">
            <div className="caixaBranca">
              <div className="logoDentroDaCaixa">
                <LogoHeader />
              </div>
              <div className="linhaLogo"></div>
              <div className="caixaMsgAssinado">
                <p className="textoAssinado">
                  Olá novamente!
                    <br></br><br></br>
                    Mais uma vez, obrigado(a) por escolher a Justweb.
                    <br></br>
                    <span className='bold'>Gostaríamos de lembrar que você já assinou o contrato anteriormente.</span>
                    <br></br>
                  Se surgirem quaisquer dúvidas entre em contato conosco pelo Tel: 0800 494 1234.
                  <br></br><br></br> Atenciosamente, Equipe Justweb.
                </p>
              </div>
              <div className="rodape">
                <Rodape />
              </div>
            </div>
          </div>
        </AppDataProvider>
      </CheckboxFieldsProvider>
    </div>
  );
};

export default PaginaAssinado;
