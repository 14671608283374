import { toast } from 'react-toastify'

export function handleSuccess(setSubmitSuccess) {
  toast('Aceite eletrônico assinado com sucesso!', {
    type: 'success',
    style: {
      minWidth: '500px',
      minHeight: '150px',
      fontSize: '22px',
    },
  });
  setSubmitSuccess(true);
}

export function handleError(error, setSubmitSuccess) {
  if (error.response && error.response.data) {
    const errorMessage = error.response.data;
    console.log('Detalhes do erro:', errorMessage);

    toast('Ops, algo deu errado. Por favor, tente novamente mais tarde ou entre em contato com o suporte se o problema persistir.', {
      type: 'error',
      style: {
        minWidth: '500px',
        minHeight: '150px',
        fontSize: '22px',
      }
    });
  } else {
    console.log('error', error);
    setSubmitSuccess(false);
    toast('ERRO:  Erro ao assinar Aceite eletrônico', {
      type: 'error',
      style: {
        minWidth: '500px',
        minHeight: '150px',
        fontSize: '22px',
      },
    });
  }
}
