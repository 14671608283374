import React from 'react';
import '../../../src/styles/LoadingIndicator.css'; 

const LoadingIndicator = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-spinner" />
        </div>
    );
};
export default LoadingIndicator;
