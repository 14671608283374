import React from 'react'
import CheckboxList from './CheckboxList'
import { Contract } from '../dados/Contract'

function CheckAgreeAll() {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const defaultItemList = [
    {
      id: 6,
      name:
        'Concordo com os termos de aceite e com todas as condições especificadas.',
      disabled: false,
    },
  ]

  return (
    <>
      <div className="CheckboxList">
        <CheckboxList defaultItemList={defaultItemList} />
      </div>
      <Contract />
    </>
  )
}
export default CheckAgreeAll