import { createContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useCheckFields } from '../hooks/useCheckFields'
import send from '../banco_dados/funcoesApi';

export const AppDataContext = createContext({})

export function AppDataProvider({ children }) {

  const [selectedFiles, setSelectedFiles] = useState([])
  const [confirmContract, setConfirmContract] = useState(false)
  const [dadosClientes , setDadosClientes] = useState([])
  const [ modalIsOpen, setModalIsOpen] = useState(false)
  const { isCheckAll, isCheck } = useCheckFields()
  const [isLoading, setIsLoading] = useState(false);
  const [showError,setShowError]= useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [cpfDigits, setCpfDigits] = useState('');
  const [ isCpfValid,setIsCpfValid] = useState(false);

  function formIsInvalid() {
    if (!isCheckAll()) {
      return true
    }

    if (selectedFiles.length === 0) {
      return true
    }

    return false
  }

  async function submit() {

    if (!isCheck(1)) {
      toast('Falta confirmar dados cliente!', { type: 'error' });
    } else if (!isCheck(2)) {
      toast('Falta aceitar o termo de contratação!', { type: 'error' });
    } else if (!isCheck(3) || !isCheck(4) || !isCheck(5)) {
      toast('Falta marcar 1 ou mais checks de declarações!', { type: 'error' });
    } else if (selectedFiles.length === 0) {
      console.log(selectedFiles.length);
      console.log('=0')
      toast('Pelo menos 2  anexos são obrigatórios!', { type: 'error' });
    } else if(selectedFiles.length ===1){
      console.log(selectedFiles.length);
      console.log('=1')
      toast('Pelo menos 2 arquivos são necessários, incluindo uma foto do seu documento de identidade e uma foto do seu rosto segurando o documento.', { type: 'error' });
    }else if (!isCheck(6)) {
      toast('Falta marcar o campo "Concordo com os termos de aceite e com todas as condições especificadas."!', { type: 'error' });
    } else if (!formIsInvalid()) {
      return await send(selectedFiles, dadosClientes, setSubmitSuccess);
    }else{
      toast('Ocorreu um erro inesperado. Por favor, atualize a página e tente novamente.', { type: 'error' });
    }
  }

  return (
    <AppDataContext.Provider
      value={{
        submit,
        selectedFiles,
        setSelectedFiles,
        confirmContract,
        dadosClientes ,
        setDadosClientes,
        setConfirmContract,
        modalIsOpen,
        setModalIsOpen,
        isLoading,
        setIsLoading,
        showError,
        setShowError,
        submitSuccess,
        setSubmitSuccess,
        cpfDigits,
        setCpfDigits,
        isCpfValid,
        setIsCpfValid,
      }}
    >
      {children}
    </AppDataContext.Provider>
  )
}