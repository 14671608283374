import React from 'react';
import './styles/App.css';
import PaginaAssinatura from './components/pages/pagina-assinatura';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CheckboxFieldsProvider } from './contexts/checkboxFieldsContext';
import { AppDataProvider } from './contexts/appDataContext';
import CookieConsent from './components/cookies/CookieConsent';

class App extends React.Component {

  render() {
    return( 
      <CheckboxFieldsProvider>
        <AppDataProvider>
          <div className="App">
            <div className="AppContainer">
              <PaginaAssinatura />            
              <ToastContainer position="top-center" className="toast-container" />
              <CookieConsent/>              
            </div>
          </div>
        </AppDataProvider>
      </CheckboxFieldsProvider>

    )
  }
}
export default App

// import React from "react";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import { Modal } from "react-bootstrap";
// import CookiePolicy from './components/cookies/CookiePolicy';
// import "./styles.css";

// export default function App() {
//   return (
//     <Router>
//       <div>
//         <h2>Click on Home</h2>
//         <ul>
//           <li>
//             <Link to="/home">Home</Link>
//           </li>
//         </ul>
//         <Switch>
//           <Route path="/:id" children={<Child />} />
//         </Switch>
//       </div>
//     </Router>
//   );
// }

// class Child extends React.Component {
//   componentDidMount() {
//     var x = document.getElementById("counter");
//     var counter = 1;
//     setInterval(function() {
//       counter++;
//       x.innerHTML = counter;
//     }, 1000);
//   }
//   render() {
//     return (
//       <>
//         <div id="counter">0</div>
//         <ShowModal />
//       </>
//     );
//   }
// }

// function ShowModal() {
//   const [show, setShow] = React.useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <div className="d-buttons">
//         <Link
//           to="/open-modal"
//           class="btn btn-danger border-0 mt-1"
//           onClick={handleShow}
//         >
//           Show Modal
//         </Link>
//       </div>
//       <Modal
//         size="lg"
//         show={show}
//         onHide={handleClose}
//         aria-labelledby="my-lg-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="my-lg-modal">
//             <h1>Política de Cookies </h1>
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <CookiePolicy/>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }
