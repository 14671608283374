import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Router, Route, Switch } from 'react-router-dom';
import PaginaObrigado from './components/pages/PaginaObrigado';
import PaginaAssinado from './components/pages/PaginaAssinado';
import PaginaTokenInvalido from './components/pages/PaginaTokenInvalido';
import PrivacyPolicy from './components/cookies/PrivacyPolicy';
import history from './hooks/history';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component= {App}/>
          <Route exact path="/assinatura-cliente/token/:token/" component= {App}/>
          <Route exact path="/obrigado" component= {PaginaObrigado}/>
          <Route exact path="/tokenInvalido" component= {PaginaTokenInvalido}/>
          <Route exact path="/tokenAssinado" component= {PaginaAssinado}/>
          <Route exact path="/privacyPolicy" component= {PrivacyPolicy}/>
        </Switch>
      </Router>
  </React.StrictMode>
);
