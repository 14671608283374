import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import '../../styles/PDFViewer.css'; // Importe o arquivo CSS


const PDFViewer = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        // Link para o PDF da política de cookies convertido a partir do docx do jurídico servido no mesmo ambiente nginx que serve o react
        const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_URL_COOKIES_ASSINATURA : process.env.REACT_APP_DEV_URL_COOKIES_ASSINATURA;

        const pdfUrl = url+`/politica_lgpd/politica_lgpd.pdf`;
        setPdfFile(pdfUrl);
      } catch (error) {
        console.error('Erro ao carregar arquivo PDF:', error);
      }
    };

    fetchPdf();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPreviousPage() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  function goToNextPage() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function downloadPdf() {
    if (pdfFile) {

      // Abre o PDF em uma nova aba
      window.open(pdfFile, '_blank');
    }
  }

  if (!pdfFile) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="pdf-container">
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          customTextRenderer={false}
        />
      </Document>
      <div className="pdf-controls">
        <p>
          Página {pageNumber} de {numPages}
        </p>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={goToPreviousPage}
        >
          Anterior
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={goToNextPage}
        >
          Próxima
        </button>
        <button
          type="button"
          onClick={downloadPdf}
        >
          Baixar PDF
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;
