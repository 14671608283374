import React from 'react'
import { useCheckFields } from '../../hooks/useCheckFields'

function CheckboxListItem(props) {
  const { isCheck } = useCheckFields()

  const check = isCheck(props.item.id)
  return (
    <li className="checkbox-list-item">
      <label>
        <input
          type="checkbox"
          disabled={props.item.disabled}
          checked={check}
          onChange={(event) => props.onCheckChanged(event, props.item)}
        />
        {props.item.name}
      </label>
    </li>
  )
}

export default CheckboxListItem