import React,{useEffect,useRef} from 'react'
import Modal from 'react-modal'
import { useAppData } from '../../hooks/useAppData.jsx'
import { useCheckFields } from '../../hooks/useCheckFields.jsx'
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import PaginaErroCarregarArquivo from "../pages/PaginaErroCarregarArquivo.jsx"

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '42%',
    left: '14%',
    transform:'translate(-10%, -50%)',
    width:'80%',
    maxWidth: '95%',
    height: '100vh',
    margin: '0 auto',
    borderRadius: '4px',
    padding: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#fff',
  },

}

export function Contract() {
  const { modalIsOpen, setModalIsOpen, setConfirmContract,dadosClientes,showError,setShowError} = useAppData()
  const { addFields } = useCheckFields() 
  const tokenAcesso = dadosClientes.token_acesso;  

  const resultado=useRef(null);
  const url_base = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_URL_COMPLETA_BACKEND : process.env.REACT_APP_DEV_URL_COMPLETA_BACKEND;

  const caminhoPdf = url_base+`/${tokenAcesso}.pdf`; // PRODUCAO `https://contrato.justwebtelecom.com.br:448/pdfs/${tokenAcesso}.pdf`
  const arquivoPdf = [
    {
      uri:caminhoPdf,
      fileType: "pdf",
      fileName: "TERMO DE ACEITE",
      accept:".pdf",
    },
  ];
  
  useEffect(()=>{
    async function obterCabecalho() {
      const url = caminhoPdf;
      const options = {
          method: 'GET',
          mode: 'cors',
      }
  
      let response = await fetch(url, options);
      var contentType = response.headers.get("content-type");
  

      console.log(contentType);
      console.log(response.status);
  
      if (response.status !== 200 || contentType !== "application/pdf"){
        resultado.current=true;
        setShowError(true);
      }else{
        resultado.current=false;
        setShowError(false);
      }
      setShowError(resultado.current);
    }

    obterCabecalho();
  }, [caminhoPdf,setShowError]);  

  const closeModal = () => {
    setModalIsOpen(false)
  }

  function handleConfirm() {
    addFields(2)
    setConfirmContract(true)
    closeModal()   
  }  

  const appElement = document.getElementById('root')

  return (
    <div >
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} appElement={appElement}>
        
        <div style={{width: '100%',height: '90%',marginTop: '10px',}}>

        {showError===true ? (
          <PaginaErroCarregarArquivo />
        ) : (

          <DocViewer        
            pluginRenderers={DocViewerRenderers}
            documents={arquivoPdf} 
            config={{
              pdfZoom: {
                defaultZoom: 1.1, 
                zoomJump: 0.2, 
              },
              pdfVerticalScrollByDefault: false,   
            }}
            language="pt-br"
            theme={{
              primary: "#5296d8",
              secondary: "#ffffff",
              tertiary: "#5296d899",
              textPrimary: "#ffffff",
              textSecondary: "#5296d8",
              textTertiary: "#00000099",
              disableThemeScrollbar: false, 
            }}                
          />
        )}

        </div>
        <div style={{paddingTop: '20px',display: 'flex',justifyContent: 'end',gap: '10px',}}>
          <button className="btn btn-danger" onClick={closeModal}>
            Fechar
          </button>

          <button className="btn btn-primary" onClick={handleConfirm} hidden = {showError}>
            Li e Concordo
          </button>
        </div>
      </Modal>
    </div>
  )
}