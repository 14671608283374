import React from 'react'
import CheckboxList from './CheckboxList'

function CheckAceites() {
  const defaultItemList = [
    {
      id: 3,
      name:
        'Declaro para os devidos fins, ter ciência sobre a obrigatoriedade da presença de uma pessoa maior de idade (plenamente capaz) no endereço de contratação durante a instalação da internet.',
      disabled: false,
    },
    {
      id: 4, 
      name:
        'Declaro, para os devidos fins, que os dados e documentos apresentados pertencem a minha pessoa, tendo ciência das sanções civis e criminais caso prestar declarações e documentos falsos.',
      disabled: false,
    },
    {
      id: 5,
      name:
        'Declaro ter ciência de que em função do recebimento dos benefícios descritos no CONTRATO DE PERMANÊNCIA, devo permanecer vinculado ao PLANO DE SERVIÇO contratado durante o prazo de 12 (doze) meses.',
      disabled: false,
    },
  ]

  return (
    <div className="CheckboxList">
      <CheckboxList defaultItemList={defaultItemList} />
    </div>
  )
}
export default CheckAceites