import React, { useState } from 'react'
import CheckboxListItem from './CheckboxListItem'
import { useCheckFields } from '../../hooks/useCheckFields'

function CheckboxList(props) {
  // const [itens, setItens] = useState(props.defaultItemList) // original
  const [itens] = useState(props.defaultItemList)

  const { addFields, removeFields } = useCheckFields()

  const checkChanged = (event, item) => {
    if (event.target.checked === true) {
      return addFields(item.id)
    }

    return removeFields(item.id)
  }

  return (
    <div className="checkboxContainer">
      <ul>
        {itens.map((item) => (
          <CheckboxListItem
            key={item.id}
            onCheckChanged={checkChanged}
            item={item}
          />
        ))}
      </ul>
    </div>
  )
}
export default CheckboxList