import React from 'react';
import Rodape from '../templatePages/Rodape';
import { CheckboxFieldsProvider } from '../../contexts/checkboxFieldsContext';
import { AppDataProvider } from '../../contexts/appDataContext';
import { useHistory } from 'react-router-dom';
import LogoHeader from '../templatePages/LogoHeader';

export const PaginaTokenInvalido = () => {
  const history = useHistory();

  return (
    <div className="paginaTokenInvalido">
      <CheckboxFieldsProvider>
        <AppDataProvider appHistory={history}>
          <div className="conteudoTokenInvalido">
            <div className="caixaBrancaTokenInvalido">
              <div className="logoDentroDaCaixa">
                <LogoHeader />
              </div>
              <div className="linhaLogo"></div>
              <div className="caixaMsgTokenInvalido">
                <p className="textoTokenInvalido">
                  Olá,
                  <br></br>
                  <br></br>
                  <span className="bold">O prazo para a assinatura desse contrato expirou.</span>
                  <br></br><br></br>
    
                  Por favor, entre em contato conosco :
                  <br></br>
                  <a target="_blank" rel="noreferrer"  
                  href="https://api.whatsapp.com/send?phone=5508004941234&text=Ol%C3%A1.%20Gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%3A">
                    <u>whatsapp - clique aqui</u></a> ou ligue: 0800 949 1234
                    <br></br>
                    <br></br>
                    Atenciosamente, Equipe Justweb.
                </p>
              </div>
              <div className="rodape">
                <Rodape />
              </div>
            </div>
          </div>
        </AppDataProvider>
      </CheckboxFieldsProvider>
    </div>
  );
};

export default PaginaTokenInvalido;
