import List from "./ListDadosClientes"
import { useAppData } from "../../hooks/useAppData";
import { useCheckFields } from '../../hooks/useCheckFields'

function DadosCliente() {
    const {dadosClientes} = useAppData(); 
    const { addFields, removeFields } = useCheckFields();

  return (
    <div className='DadosCliente'> 
      <List             
        token_id={dadosClientes.cd_token}
        Nome={dadosClientes.nm_cliente}
        Plano={dadosClientes.nm_plano} 
        end_rua = {dadosClientes.en_rua}
        end_numero = {dadosClientes.en_numero}
        end_bairro={dadosClientes.en_bairro}
        end_cidade = {dadosClientes.en_cidade}
        end_estado = {dadosClientes.en_estado}
        end_cep = {dadosClientes.en_cep}
        end_complemento={dadosClientes.en_complemento}
      />

      <div className="confirmation-checkbox">
        <input
          type="checkbox"
          id="confirmClient"
          name="confirmClient"
          onChange={(event) => {
            if (event.target.checked) {
              addFields(1); // Adicione o ID apropriado para o novo checkbox
            } else {
              removeFields(1); // Remova o ID apropriado para o novo checkbox
            }
          }}
        />
        <label htmlFor="confirmClient" style={{ fontWeight: "bold", fontSize: "14px", marginLeft: '5px' }}>
        Estou ciente de que o endereço de instalação é o indicado acima e confirmo que todos os dados estão corretos.
        </label>
      </div>
    </div>
  );
}

export default DadosCliente;