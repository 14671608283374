
import { React } from 'react'
import DadosCliente from '../dados/DadosCliente'
import CheckAceites from '../checkbox/CheckAceites'
import TituloPagina from './TituloPagina'
import CheckTermosContratacao from '../checkbox/CheckTermosContratacao'
import { useAppData } from '../../hooks/useAppData.jsx'
import { FileUploadComponent } from '../arquivos/FileUploadComponent'
import CheckAgreeAll from '../checkbox/CheckAgreeAll'
import LoadingIndicator from '../icone/iconProcessando.jsx'
import { useHistory } from 'react-router-dom';

export const Conteudo = ()  => {
  const history = useHistory();
  const { submit,submitSuccess ,isLoading, setIsLoading} = useAppData();
  
  async function handleSubmit() {
    try {
      setIsLoading(true);
      await submit();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }    
  }

  if (submitSuccess) {
    history.replace('/obrigado');
    window.location.replace('/obrigado');
  }

  return (
    <div>
      <div className={`overlay ${isLoading ? 'show' : ''}`} /> 
      <TituloPagina tituloh3="Dados cliente" />
      <DadosCliente/>
      <TituloPagina tituloh3="Termo de contratação" />
      <CheckTermosContratacao />
      <TituloPagina tituloh3="Declarações" />
      <CheckAceites />
      <TituloPagina tituloh3="Anexos" />
      <FileUploadComponent />
      <TituloPagina tituloh3="Termo de aceite" />
      <CheckAgreeAll />

      <div className="divBotoes">
        <button className="botoes" onClick={handleSubmit} disabled={isLoading}>
          Confirmar
        </button>
      </div>
      {/* Indicador de carregamento */}
      {isLoading && <LoadingIndicator />}
    </div>
  )
}
export default Conteudo